import React, { ReactElement } from 'react';

const PageDeleted = (): ReactElement => {
  return (
    <div>
      <h1>Ooops! Page deleted.</h1>
      <p>Error 410</p>
    </div>
  );
};

export default PageDeleted;
